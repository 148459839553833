import React, { useContext, useEffect } from 'react'
import IFrame from 'react-iframe'
import { useHistory } from 'react-router-dom'
import Config from '../config'
import { AppContext } from '../context/AppContext'
import styles from './MainView.module.scss';
import cam from '../assets/images/cam.png'
import { useMediaQuery } from '../hooks/useMediaQuery';


interface Props {
    
}

const MainView = (props: Props) => {


    const isMobile = useMediaQuery('(max-width: 768px)')
    const { widgetToken, setWidgetToken, setEnrollData } = useContext(AppContext)
    const history = useHistory()

    useEffect(() => {
        if(!widgetToken){
            history.push('/')
        }
    }, [widgetToken, history])

    const logout = () => {
        setWidgetToken?.(undefined)
        window.localStorage.clear()
        setEnrollData?.(undefined)
        history.push('/')
        // window.location.href='https://congreso.escuelaplus.com'
    }


    return (
        <div className={styles.container}>
            
            <div className={styles.logoutContainer}>
                <button onClick={logout} className={styles.logoutButton}>{'< Volver al sitio del evento'}</button>
                {/* {isMobile && <a target="_blank" rel="noreferrer" href={"https://eggeducacion.zoom.us/j/98395667267"} className={styles.camButton}><img src={cam} width={30} alt="" /></a>} */}
            </div>
            
            <div className={styles.logoContainer}>
                {/* <img src={LogoDark} alt="Escuela+ Egg Logo" /> */}
            </div>

            <div className={styles.cardContainer}>
                <div className={styles.leftCard}>
                    <h1>Te damos la bienvenida a Egg</h1>
                    <p>Nuestro propósito es potenciar la cooperación humana. Hoy tendremos una sesión de trabajo en la que cada uno será protagonista desde el rol de estudiante, vivenciando nuestra tecnología social.</p>
                    {/* <div className={styles.schedule}>
                        <div>
                            <ul>
                                <li><strong>10:55</strong> COLOMBIA | PERU | ECUADOR | MEXICO</li>
                                <li><strong>11:55</strong> VENEZUELA</li>
                                <li><strong>12:55</strong> ARGENTINA | BRASIL | URUGUAY | SANTIAGO</li>
                            </ul>
                        </div>
                        <div>
                            Accede a la reunión a través del siguiente botón
                            <a target="_blank" rel="noreferrer" href={"https://eggeducacion.zoom.us/j/98395667267"} className={styles.camButton}><img src={cam} width={30} alt="" /></a>
                        </div>
                    </div> */}
                    <p><strong></strong></p>
                </div>
            </div>

            <IFrame url={`${Config.WIDGET_URL}?token=${widgetToken}`}
            id="myId"
            className={styles.widgetFrame}
            />
        
        </div>
    )
}

export default MainView
