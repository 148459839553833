import axios, { AxiosResponse } from 'axios';
import { FormEvent, useContext, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom';
import Logo from '../assets/images/egg_shaddow.svg';
import Config from '../config';
import { AppContext } from '../context/AppContext';
import SignupLayout from '../layouts/SignupLayout';
import styles from './SignupForm.module.scss';
import i1 from '../assets/images/icons/001-user.png'
import i3 from '../assets/images/icons/002-email.png'
import Recaptcha from 'react-google-invisible-recaptcha';
import { useMediaQuery } from '../hooks/useMediaQuery';
import LogoDark from '../assets/images/event_logo_dark.svg'


interface IProps {
    onFormSubmit?: () => void
}

const SignupForm = ({onFormSubmit, ...props}:IProps) => {

    const { enrollData, setWidgetToken, widgetToken } = useContext(AppContext)
    const history = useHistory()
    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [email,setEmail] = useState('')
    const [error,setError] = useState<string | undefined>(undefined)
    const [fieldsError,setFieldsError] = useState<string | undefined>(undefined)
    const [ready, setReady] = useState<boolean>(true)
    const isMobile = useMediaQuery('(max-width: 768px)')

    const refRecaptcha = useRef<any>(null);

    const formSubmit = async (e: FormEvent) => {
        e.preventDefault()

        // Getting captcha token
        try{
            setReady(false)
            const captchaToken = await refRecaptcha.current.callbacks.execute();
            if(name && email && lastname){
                try{
                    const res = await axios.post<AxiosResponse<{widgetToken: string}>>(Config.WIDGET_API + '/v1/auth/landing-singup',
                    {
                        name,
                        lastname,
                        email,
                        token: enrollData?.token,
                        captcha_token: captchaToken
                    })
                    if(res?.data.data.widgetToken){
                        setWidgetToken?.(res.data.data.widgetToken)
                    }
                    history.push('/widget')
                } catch(error: any) {
                    setError('La Inscripcion al evento esta Cerrada')
                }                
            } else {
                setFieldsError('Debe completar todos los datos')
                alert('Must complete all the fields')
            }
        } catch(error: any) {
            console.error('Error procesando los datos')
            setError('Error procesando los datos')
        } finally {
            setReady(true)
        }
    }

    const retry = () => {
        setWidgetToken?.(undefined)
        window.localStorage.clear()
        setError(undefined)
    }

    if(widgetToken){
        return <Redirect to="/widget"/>
    }

    if(!enrollData){
        return(
            <SignupLayout>
                <div className={styles['form-container']}>
                    <div className="formHeading">
                        <img src={LogoDark} width={154} alt="" />
                        <p>{`Debe ingresar mediante un link de invitación`}</p>
                    </div>
                </div>
            </SignupLayout>
        )
    }

    if(error){
        return(
            <SignupLayout>
                <div className={styles['form-container']}>
                    <div className="formHeading">
                        <img src={Logo} width={60} alt="" />
                        <h1>Espacio Egg</h1>
                        <p>{error}</p>
                        <button onClick={retry} className={styles.submit}>Reintentar</button>
                    </div>
                </div>
            </SignupLayout>
        )
    }

    return (
        <SignupLayout>
            <div className={styles['form-container']}>
                <div className="formHeading">
                    {isMobile ? <img src={LogoDark} width={'40%'} alt="" /> : <img src={LogoDark} width={150} alt="" />}
                    <h1>Completa tus datos</h1>
                    <p>{`Ingresa tus datos para ser parte de un equipo. En breve verás con quién trabajarás en la sesión de hoy.`}</p>
                </div>
                {fieldsError && <span>Debe completar todos los campos</span>}
                <form className={styles.form} onSubmit={formSubmit}>
                    <div className={styles.fieldContainer}>
                        <img className={styles.fieldIcon} src={i1} alt="" />
                        <input className={styles.field} type="text" name="name" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className={styles.fieldContainer}>
                        <img className={styles.fieldIcon} src={i1} alt="" />
                        <input className={styles.field} type="text" name="lastname" placeholder="Apellido" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                    <div className={styles.fieldContainer}>
                        <img className={styles.fieldIcon} src={i3} alt="" />
                        <input className={styles.field} type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <button className={styles.submit} disabled={!name || !lastname || !email} type="submit">{ready ? 'Ingresar' : '...'}</button>
                    <Recaptcha onError={() => setError('Error validating captcha')} onResolved={() => undefined} ref={refRecaptcha} sitekey={Config.CAPTCHA_KEY} />
                </form>
            </div>
        </SignupLayout>
    )
}

export default SignupForm
