import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import MainView from "../components/MainView";
import NotFoundPage from "../components/NotFoundPage";
import SignupForm from '../components/SignupForm';
import EnrollView from "../components/EnrollView";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";

interface Props {
    
}

const AppRouter = (props: Props) => {

    const { setWidgetToken, widgetToken } = useContext(AppContext)
    
    useEffect(() => {
        const token = window.localStorage.getItem('widgetToken')
        if(token){
            setWidgetToken?.(token)
        }
    },[setWidgetToken])


    // if(widgetToken){
    //     return <BrowserRouter><Redirect to="/widget"/></BrowserRouter>
    // }

    return (
        <div className="demo-site-container">
            <BrowserRouter>
                <Switch>
                    <Route exact path="/enroll/:token" component={EnrollView} />
                    <Route exact path="/" component={SignupForm} />
                    <Route path="/widget" component={MainView} />
                    <Route path="*" component={SignupForm}/>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default AppRouter
