import React from 'react'
import styles from './SignupLayout.module.scss';
interface Props {
    children: JSX.Element
}

const SignupLayout = ({children}: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.imgContainer}>
                    {/* <img src={LogoLight} width={100} alt="Escuela+ Egg Logo" /> */}
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.leftCard}>
                        <h1>Te damos la bienvenida a Egg</h1>
                        <p>Nuestro propósito es potenciar la cooperación humana. Hoy tendremos una sesión de trabajo en la que cada uno será protagonista desde el rol de estudiante, vivenciando nuestra tecnología social.</p>
                    </div>
                </div>
            </div>
            <div className={styles.right}>{children}</div>
        </div>
    )
}

export default SignupLayout
