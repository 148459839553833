import React, { createContext, useEffect, useState } from "react";
import { IEnrollResponse } from "../interfaces/mainInterfaces";


interface IContext {
    enrollData: IEnrollResponse | undefined,
    setEnrollData: React.Dispatch<React.SetStateAction<IEnrollResponse | undefined>>
    widgetToken: undefined | string,
    setWidgetToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const AppContext = createContext<Partial<IContext>>({})
AppContext.displayName = 'App Context'

export const AppContextProvider = (props: { children: JSX.Element }) => {

    const [ enrollData, setEnrollData ] = useState<IEnrollResponse | undefined>(undefined)
    const [ widgetToken, setWidgetToken ] = useState<undefined | string>(undefined) 

    // if widget token or enrollData are set, save on localhost
    useEffect(() => {
        if(widgetToken){
            window.localStorage.setItem('widgetToken',widgetToken)
        }
        if(enrollData){
            window.localStorage.setItem('enrollData', JSON.stringify(enrollData))
        } else {
            const data = window.localStorage.getItem('enrollData')
            if(data){
                setEnrollData(JSON.parse(data))
            }
        }
    },[widgetToken, enrollData])

    const value = {
        enrollData,
        setEnrollData,
        widgetToken,
        setWidgetToken
    }

    return(
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    )
}

